<template lang="pug">
div
  b-row(v-show="isSubmitting")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          color="#498ceb"
        )
  Form(
    v-show="!isSubmitting",
    :data="complaintData",
    :updateActiveTab="updateActiveTab",
    @submit="update",
    @addNewMessage="addNewMessage",
    @isSubmitting="isSubmitting = true"
  )
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
import useComplaintEdit from './useComplaintEdit'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Form,
    Loading,
  },
  setup() {
    const { update, show, complaintData, isSubmitting, updateActiveTab, addNewMessage } = useComplaintEdit()

    onMounted(show)

    return {
      complaintData,
      update,
      isSubmitting,
      updateActiveTab,
      addNewMessage,
    }
  },
}
</script>
